import css from "./hero.scss?inline";
import globalStyles from "../../index.scss?inline";
import { ArgSpecDictionary } from "../component-utils";
import { HHDSVideoVimeo, HHDSVideoVimeoAttrNames, HHDSVideoVimeoTagName } from "../VideoVimeo/VideoVimeo";
import { HHDSModal, HHDSModalEvent } from "../Modal/Modal";
import { VideoPlayCursor } from "../../utils/VideoPlayCursor";
import { Component } from "../../utils/Component";
import { HHDSVideoDirect, HHDSVideoDirectTagName } from "../VideoDirect/VideoDirect";

const DEBUG_VERBOSE: boolean = false;
const CLASS_NAME: string = "HHDSHero";
export const HHDSHeroTagName: string = "hhds-hero";
//const TAG_NAME: string = HHDSHeroTagName;

const USE_HHDS_IMAGE: boolean = false;

export enum HHDSHeroType {
  image = "image",
  video = "video",
}

enum HorizontalLayoutType {
  fullwidth = "fullwidth",
  fullbleed = "fullbleed",
  margins = "margins",
}

export const HHDSHeroAttrNames = {
  type: "type",
  horizontalLayout: "horizontal-layout",
  src: "src",
  cover: "cover",
  modalSrc: "modal-src",
  modalCover: "modal-cover",
  directVideo: "direct-video",
  dynamicCursor: "dynamic-cursor",
};

const Attrs = HHDSHeroAttrNames;

export class HHDSHero extends Component {
  private cursorElement?: HTMLDivElement;
  private cursor: VideoPlayCursor | null = null;
  private clickFunc: any;
  private modalHideFunc: any;
  private pointerEnterFunc: any;
  private pointerLeaveFunc: any;
  private pointerMoveFunc: any;

  private attemptModalAutoplay: boolean = true;

  constructor() {
    super();
    this.clickFunc = (_event: Event) => {
      if (this.modalAvailable) {
        let modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
        if (modal) {
          if (!modal.coordinator) {
            modal.createCoordinator();
          }
          this.updateModalVisibility(true, true);
        }
      }
    };
  }

  protected override init(): void {
    let nestedComponentHtml: string = "";
    const src = this.vars.get<string>(Attrs.src);
    let type = this.vars.get<HHDSHeroType>(Attrs.type);

    const horizontalLayoutType = this.vars.get<HorizontalLayoutType>(Attrs.horizontalLayout);
    switch (horizontalLayoutType) {
      case HorizontalLayoutType.fullwidth:
        this.style.setProperty("--hero-max-width", "1920px");
        break;
      case HorizontalLayoutType.fullbleed:
        this.style.setProperty("--hero-max-width", "none");
        break;
      case HorizontalLayoutType.margins:
        this.style.setProperty("--hero-height-lg", "unset");
        break;
    }

    if (this.useDynamicCursor) {
      this.modalHideFunc = (_event: Event) => {
        this.updateModalVisibility(false, false);
        this.cursor?.setPaused(false);
      };
      this.pointerLeaveFunc = (_event: Event) => {
        this.cursor?.setHidden(true);
        this.cursor?.setPaused(true);
      };
      this.pointerEnterFunc = (_event: Event) => {
        this.cursor?.setPaused(false);
      };
    } else {
      this.modalHideFunc = (_event: Event) => {
        this.updateModalVisibility(false, false);
      };
    }

    // if src contains jpg or png, it's an image
    if (src.includes(".jpg") || src.includes(".jpeg") || src.includes(".png")) {
      type = HHDSHeroType.image;
    }
    switch (type) {
      case HHDSHeroType.image:
        nestedComponentHtml = this.getImageHtml(src);
        break;
      case HHDSHeroType.video:
        const cover = this.vars.get<string>(Attrs.cover);
        //const cover = "https://dummyimage.com/100x100/330000/fff";
        nestedComponentHtml = this.getVideoHtml(src, cover);
        break;
    }

    type = this.vars.get<HHDSHeroType>(Attrs.type);

    let modalComponentHtml: string = "";
    if (this.modalAvailable) {
      modalComponentHtml = this.getModalHtml(
        this.vars.get<string>(Attrs.modalSrc),
        this.vars.get<HHDSHeroType>(Attrs.type),
        this.vars.get<string>(Attrs.modalCover)
      );
    }

    let largeOpenButtonHtml: string;
    let smallOpenButtonHtml: string;
    let cursorHtml: string;
    if (this.modalAvailable) {
      largeOpenButtonHtml = `<div class="large-open-button"></div>`;
      smallOpenButtonHtml = `<hhds-button class="small-open-button" type='overlay'>PLAY</hhds-button>`;
      cursorHtml = `<div class="play-cursor hidden"><p class="ui play-cursor-label">PLAY</p></div>`;
    } else {
      largeOpenButtonHtml = "";
      smallOpenButtonHtml = "";
      cursorHtml = "";
    }

    this.shadow.innerHTML = `
    <style>${globalStyles}</style>
    <style>${css}</style>
    <div id="container" class="container">
      <div id="grid" class="grid">
        <div id="grid-inner" class="col-span-12 md:col-span-12">
          <div class="hhds-hero">
            <div class="container">
              <div class="grid">
                <div class="col-start-1">
                  ${smallOpenButtonHtml}	
                </div>
              </div>
            </div>
            ${largeOpenButtonHtml}
            ${modalComponentHtml}
            ${nestedComponentHtml}
            ${cursorHtml}
            <div class="bottom-anchor"></div>
          </div>
        </div>
      </div>
    </div>
    `;

    this.cursorElement = this.shadow.querySelector(".play-cursor") as HTMLDivElement;
    if (this.cursorElement && this.cursorElement.parentElement) {
      this.cursor?.dispose();
      const fixedSizePx = 80;
      this.cursor = new VideoPlayCursor(
        this.cursorElement,
        this.cursorElement.parentElement,
        0,
        -15,
        fixedSizePx
      );
      if (this.useDynamicCursor) {
        this.cursor.onDeadzoneEnter = () => {
          this.cursor?.setHidden(true);
        };
        this.cursor.onDeadzoneExit = () => {
          if (!this.cursor?.overlappingAnyObstacle) this.cursor?.setHidden(false);
        };
        this.cursor.onObstacleEnter = () => {
          this.cursor?.setHidden(true);
        };
        this.cursor.onObstacleLeave = () => {
          if (!this.cursor?.overlappingAnyObstacle) this.cursor?.setHidden(false);
        };
        this.cursor.onMove = (_x: number, _y: number) => {
          if (!this.cursor?.overlappingAnyObstacle) this.cursor?.setHidden(false);
        };
      } else {
        // Only use the dynamic cursor if the layout is not the 'margins' variant
        this.cursor?.setPaused(true);
        this.cursor?.setHidden(false);
        this.cursorElement.classList.add("static-centered");
        const largeOpenButton = this.shadow.querySelector(".large-open-button") as HTMLDivElement;
        largeOpenButton.style.cursor = "pointer";
      }
    }

    this.addListeners();

    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    modal?.setVideoPlaying(false);

    if (horizontalLayoutType == HorizontalLayoutType.margins) {
      this.observeBreakpointChanges((breakpoint: string) => this.updateForBreakpoint(breakpoint));
    }
    this.updateForBreakpoint(this.currentBreakpoint);
  }

  private updateForBreakpoint(breakpoint: string | null = null) {
    const horizontalLayoutType = this.vars.get<HorizontalLayoutType>(Attrs.horizontalLayout);
    const margins = horizontalLayoutType == HorizontalLayoutType.margins;
    const useGrid = breakpoint != "xs" && breakpoint != "sm" && margins;
    const container = this.shadow.querySelector("#container") as HTMLElement;
    container.style.width = "100%";
    const grid = this.shadow.querySelector("#grid");
    const gridInner = this.shadow.querySelector("#grid-inner");
    container?.classList.toggle("container", useGrid);
    grid?.classList.toggle("grid", useGrid);
    gridInner?.classList.toggle("col-span-12", useGrid);
    gridInner?.classList.toggle("md:col-span-12", useGrid);

    gridInner?.classList.toggle("hhds-hero--ignore", !useGrid);
    grid?.classList.toggle("hhds-hero--ignore", !useGrid);
    container?.classList.toggle("hhds-hero--ignore", !useGrid);
    console.log("updateForBreakpoint", breakpoint, container);
  }

  get useDynamicCursor(): boolean {
    if (!this.vars.has(Attrs.dynamicCursor)) return true;
    console.log("useDynamicCursor", this.vars.get<boolean>(Attrs.dynamicCursor));
    return this.vars.get<boolean>(Attrs.dynamicCursor);
  }

  protected override destroy(): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "destroy");
    this.removeListeners();
  }

  override onAttributeChanged(name: string, _oldValue: string, newValue: string): void {
    DEBUG_VERBOSE && console.log(CLASS_NAME, "Attribute changed: ", name, _oldValue, newValue);
    this.reinit();
  }

  override onSlotChange(_slot: HTMLSlotElement, elements: Element[]): void {
    if (elements.length == 0) {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot emptied");
    } else {
      DEBUG_VERBOSE && console.log(CLASS_NAME, "Slot changed");
    }
  }

  get bottomAnchor(): HTMLElement {
    return this.shadow.querySelector(".bottom-anchor") as HTMLElement;
  }

  getCursor(): VideoPlayCursor | null {
    return this.cursor;
  }

  static override argSpecs(): ArgSpecDictionary {
    return ArgSpecs;
  }

  private get videoTagName(): string {
    return this.vars.get<boolean>(Attrs.directVideo) ? HHDSVideoDirectTagName : HHDSVideoVimeoTagName;
  }

  private get mainVideo(): HHDSVideoVimeo | HHDSVideoDirect {
    const videoContainer = this.shadow.querySelector(".video-container") as HTMLElement;
    return videoContainer?.querySelector(this.videoTagName) as HHDSVideoVimeo | HHDSVideoDirect;
  }

  async isPlaying(): Promise<boolean> {
    return this.mainVideo?.isPlaying() ?? false;
  }

  pause(): void {
    this.mainVideo?.pause();
  }

  play(): void {
    this.mainVideo?.play();
  }

  private addListeners(): void {
    const smallOpenButton = this.shadow.querySelector(".small-open-button") as HTMLDivElement;
    if (smallOpenButton) smallOpenButton.addEventListener("click", this.clickFunc);
    const largeOpenButton = this.shadow.querySelector(".large-open-button") as HTMLDivElement;
    if (largeOpenButton) {
      largeOpenButton.addEventListener("click", this.clickFunc);
      largeOpenButton.addEventListener("pointermove", this.pointerMoveFunc);
      largeOpenButton.addEventListener("pointerleave", this.pointerLeaveFunc);
      largeOpenButton.addEventListener("pointerenter", this.pointerEnterFunc);
    }
    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    modal?.addEventListener(HHDSModalEvent.hide, this.modalHideFunc);
  }

  private removeListeners(): void {
    const smallOpenButton = this.shadow.querySelector(".small-open-button") as HTMLDivElement;
    if (smallOpenButton) smallOpenButton.removeEventListener("click", this.clickFunc);
    const largeOpenButton = this.shadow.querySelector(".large-open-button") as HTMLDivElement;
    if (largeOpenButton) {
      largeOpenButton.removeEventListener("click", this.clickFunc);
      largeOpenButton.removeEventListener("pointermove", this.pointerMoveFunc);
      largeOpenButton.removeEventListener("pointerleave", this.pointerLeaveFunc);
      largeOpenButton.removeEventListener("pointerenter", this.pointerEnterFunc);
    }
    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    modal?.removeEventListener(HHDSModalEvent.hide, this.modalHideFunc);
  }

  updateModalVisibility(visible: boolean, applyToModal: boolean): void {
    const modal = this.shadow.querySelector("hhds-modal") as HHDSModal;
    console.log("[Hero] updateModalVisibility, visible=" + visible);
    if (!modal) return;

    visible ? this.pause() : this.play();

    if (applyToModal) {
      if (visible) {
        modal.show();
        if (this.attemptModalAutoplay) {
          modal.setVideoPlaying(true, false);
        } else {
          const videoVimeo = modal.getSlotted<HHDSVideoVimeo>("hhds-video-vimeo");
          if (videoVimeo) videoVimeo.createPlayer();
        }
      } else {
        modal.hide();
        modal.setVideoPlaying(false);
      }
    }

    if (this.useDynamicCursor) this.cursor?.setHidden(true);
    const largeOpenButton = this.shadow.querySelector(".large-open-button") as HTMLDivElement;
    largeOpenButton.setAttribute("disabled", visible ? "true" : "false");
    const fixedOpenButton = this.shadow.querySelector(".small-open-button") as HTMLDivElement;
    fixedOpenButton.setAttribute("disabled", visible ? "true" : "false");
  }

  get modalAvailable(): boolean {
    return (
      this.vars.get<string>(Attrs.modalSrc) != "" &&
      this.vars.get<HHDSHeroType>(Attrs.type) == HHDSHeroType.video
    );
  }

  private getImageHtml(src: string): string {
    if (USE_HHDS_IMAGE) {
      const style: string = `
      --image-object-fit: cover;
      --image-width: 100%;
      --image-height: 100%`;
      return `<hhds-image src="${src}"
        ${this.getAttribute("srcSet") !== null ? `srcset="${this.getAttribute("srcSet")}` : ``}"
        ${this.getAttribute("sizes") !== null ? `sizes="${this.getAttribute("sizes")}` : ``}"
        style="${style}"></hhds-image>`;
    } else {
      return `<div class="image-container"><img class="img"
        ${this.getAttribute("srcSet") !== null ? `srcset="${this.getAttribute("srcSet")}` : ``}"
        ${this.getAttribute("sizes") !== null ? `sizes="${this.getAttribute("sizes")}` : ``}"
        src="${src}"></img></div>`;
    }
  }

  private getVideoHtml(src: string, cover: string): string {
    // The core attributes for HHDSVideoDirect and HHDSVideoVimeo should be identical,
    // so it should be safe to use HHDSVideoVimeoAttrNames here.
    let attributes = {
      [HHDSVideoVimeoAttrNames.src]: src,
      [HHDSVideoVimeoAttrNames.cover]: cover,
      [HHDSVideoVimeoAttrNames.autoplay]: "true",
      [HHDSVideoVimeoAttrNames.loop]: "true",
      [HHDSVideoVimeoAttrNames.controls]: "false",
    };

    let attributesString: string = "";
    Object.keys(attributes).forEach((key) => (attributesString += `${key}="${attributes[key]}" `));

    let html: string = `<div class="video-container">`;
    if (this.vars.get<boolean>(Attrs.directVideo)) {
      html += `<hhds-video-direct id="main" ${attributesString}></hhds-video-direct>`;
    } else {
      const style = `
      --video-vimeo-width: 100%;
      --video-vimeo-height: 100%;
      --video-vimeo-aspect-ratio: unset;
      --video-vimeo-iframe-width: 100vw;
      --video-vimeo-iframe-height: 56.25vw;
      --video-vimeo-iframe-min-width: 177.77vh;
      --video-vimeo-iframe-min-height: 100vh;
      `;
      html += `<hhds-video-vimeo id="main" style="${style}" ${attributesString}></hhds-video-vimeo>`;
    }
    html += `</div>`;
    return html;
  }

  private getModalHtml(src: string, type: HHDSHeroType, cover?: string): string {
    if (type == HHDSHeroType.image) {
      return `<hhds-modal><hhds-image 
        src="${src}" 
        ${this.getAttribute("srcSet") !== null ? `srcset="${this.getAttribute("srcSet")}` : ``}"
        ${this.getAttribute("sizes") !== null ? `sizes="${this.getAttribute("sizes")}` : ``}"
      ></hhds-image></hhds-modal>`;
    }

    let attributes = {
      [HHDSVideoVimeoAttrNames.src]: src,
      [HHDSVideoVimeoAttrNames.autoplay]: "true",
      [HHDSVideoVimeoAttrNames.loop]: "false",
      [HHDSVideoVimeoAttrNames.controls]: "true",
      [HHDSVideoVimeoAttrNames.hidePlayButton]: this.attemptModalAutoplay ? "true" : "false",
      [HHDSVideoVimeoAttrNames.recreatePlayer]: "false",
      [HHDSVideoVimeoAttrNames.mute]: "false",
    };
    if (cover) {
      attributes[HHDSVideoVimeoAttrNames.cover] = cover;
    }
    let attributesString: string = "";
    Object.keys(attributes).forEach((key) => (attributesString += `${key}="${attributes[key]}" `));
    const output = `<hhds-modal><hhds-video-vimeo id="in-modal" ${attributesString}></hhds-video-vimeo></hhds-modal>`;
    return output;
  }
}

export const ArgSpecs: ArgSpecDictionary = {
  [Attrs.type]: {
    description: "Type of content to display.",
    defaultValue: HHDSHeroType.image,
    type: HHDSHeroType,
    typeString: "HHDSHeroType",
  },
  [Attrs.horizontalLayout]: {
    description: "Type of horizontal layout to use",
    defaultValue: HorizontalLayoutType.fullwidth,
    typeString: "HorizontalLayoutType",
    type: HorizontalLayoutType,
  },
  [Attrs.src]: {
    description: "URL to an image or a Vimeo-hosted video.",
    defaultValue: "",
    type: String,
  },
  [Attrs.cover]: {
    description: "The URL of the cover image, if applicable.",
    defaultValue: "",
    type: String,
  },
  [Attrs.modalSrc]: {
    description: "URL to a Vimeo-hosted video that will open in a modal when the hero is activated.",
    defaultValue: "",
    type: String,
  },
  [Attrs.modalCover]: {
    description: "The URL of the cover image for the modal, if applicable.",
    defaultValue: "",
    type: String,
  },
  [Attrs.directVideo]: {
    description: "Whether to use direct video instead of the Vimeo player for the Hero's initial video.",
    defaultValue: true,
    type: Boolean,
  },
  [Attrs.dynamicCursor]: {
    description: "Whether to use a dynamic cursor for the Hero.",
    defaultValue: true,
    type: Boolean,
  },
};
